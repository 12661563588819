import {Slider} from '@components/slider';

interface Props {
    disabled?: boolean;
}

export const HoursInput = (props: Props) => {
    return (
        <>
            <Slider
                disabled={props.disabled}
                name={`saturday`}
                label="Leygardag"
                maxValue={8}
                minValue={0}
                step={1}
                renderLabel={(value) => <>{props.disabled ? '-' : value} tímar</>}
            />
            <Slider
                disabled={props.disabled}
                name={`sunday`}
                label="Sunnudag"
                maxValue={8}
                minValue={0}
                step={1}
                renderLabel={(value) => <>{props.disabled ? '-' : value} tímar</>}
            />
        </>
    )
}
