import {useCalculatorInput} from '@components/salary-calculator/provider';

export const HoursOutput = () => {
    const {saturday, sunday, weeks} = useCalculatorInput()
    const dayShiftTotal = ((sunday + saturday) * weeks).toFixed(1)

    return (
        <ul>
            <li className="flex justify-between">
                <span>Leygardag</span>
                <output><span className="text-rodin-900">{weeks} x</span> {saturday} tímar</output>
            </li>
            <li className="flex justify-between">
                <span>Sunnudag</span>
                <output><span className="text-rodin-900">{weeks} x</span> {sunday} tímar</output>
            </li>
            <li className="flex justify-between">
                <span className="font-black text-rodin-900">Tilsamans</span>
                <output className="font-black text-rodin-900">{dayShiftTotal} tímar</output>
            </li>
        </ul>
    )
}
