import {Formik, useFormikContext} from 'formik'
import {ReactNode, useMemo} from 'react';
import {zipWith} from 'lodash'
import {add, dinero, Dinero, multiply, toUnit} from 'dinero.js'
import {DKK} from '@dinero.js/currencies'
import {Position, ShiftHours, ShiftValue} from 'lib/types';

export interface SalaryCalculatorData {
    education: string;
    saturday: number;
    sunday: number;
    weeks: number;
}


const formInitial: SalaryCalculatorData = {
    education: 'none',
    saturday: 2,
    sunday: 0,
    weeks: 2
}


export const SalaryCalculatorProvider = (props: { children: ReactNode, initialValues: Partial<SalaryCalculatorData> | null, onSubmit: (values: SalaryCalculatorData) => Promise<void> }) => {
    const onSubmit = async (values: typeof formInitial) => {
        await props.onSubmit(values)
    }
    const initial = useMemo(() => Object.assign({}, formInitial, props.initialValues), [props.initialValues])

    return (
        <Formik initialValues={initial} onSubmit={onSubmit}>
            {props.children}
        </Formik>
    )
}

export function useCalculatorInput() {
    return useFormikContext().values as typeof formInitial
}

type Hours = ShiftValue<number>


const HOURS_ZERO: ShiftHours = {
    saturday: 0,
    sunday: 0
}


type Rates = {
    saturday: Dinero<number>;
    sunday: Dinero<number>;
}
const DEFAULT_RATES: Rates = buildRates({
    // weekdays: 132_00,
    saturday: 168_00,
    sunday: 197_00
})


const RATES_HEILSUHJALP: Rates = buildRates({
    // day: {
    // weekdays: 144_91,
    saturday: 184_76,
    sunday: 217_37,
    // },
    // night: {
    // weekdays: 189_99,
    // saturday: 229_84,
    // sunday: 262_45
    // }
})


const RATES_HEILSUROKT: Rates = buildRates({
    // day: {
    // weekdays: 153_09,
    saturday: 195_19,
    sunday: 229_64,
    // },
    // night: {
    // weekdays: 200_72,
    // saturday: 242_82,
    // sunday: 277_27
    // }
})

const RATES_SJUKRAROKT: Rates = buildRates({
    // day: {
    // weekdays: 162_02,
    saturday: 200_41,
    sunday: 243_02,
    // },
    // night: {
    //     weekdays: 207_55,
    // saturday: 245_95,
    // sunday: 288_56
    // }
})

function buildRates(rates: ShiftValue<number>): Rates {
    return inflate(flatten(rates).map(r => dinero({amount: r, currency: DKK})))
}

export function useRates(): Rates {
    return DEFAULT_RATES
}


export function useRateClass(education: string | null) {
    return useMemo(() => {

        switch (education) {
            case 'heilsurokt':
                return RATES_HEILSUROKT;
            case 'sjukrarokt':
                return RATES_SJUKRAROKT;
            case 'heilsuhjalp':
                return RATES_HEILSUHJALP;
            default:
                return DEFAULT_RATES
        }
    }, [education])
}

function inflate<T>(raw: T[]): ShiftValue<T> {
    return {
        saturday: raw[0],
        sunday: raw[1]
    }
}

function flatten<T>(shiftLike: ShiftValue<T>): T[] {
    return [shiftLike.saturday, shiftLike.sunday]
}

/** Pure */
function computeSalaryTotal(hours: Hours, rates: Rates): number {
    console.dir({hours, rates});
    const amount = toUnit(zipWith(flatten(hours), flatten(rates), (hour, rate) => multiply(rate, {
            amount: Math.round(hour * 10),
            scale: 1
        }))
            .reduce((r1: Dinero<number>, r2: Dinero<number>) => add(r1, r2), dinero({
                amount: 0,
                currency: DKK
            }))
    )
    if (amount > 1000 && amount % 10 === 0) {
        return amount - 1
    } else {
        return amount
    }

}


export function useComputedSalary() {
    const input = useCalculatorInput()
    const rates = useRateClass(input.education)
    const hours = useCalculatorInput()
    return computeSalaryTotal({saturday: hours.weeks * hours.saturday, sunday: hours.weeks * hours.sunday}, rates)
}

