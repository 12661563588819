import classNames from 'classnames';
import {Field, Form, useFormikContext} from 'formik';
import {useCalculatorInput} from '@components/salary-calculator/provider';
import {Button} from '@components/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {SalaryCalculatorOutput} from '@components/salary-calculator/output';
import {useMedia} from 'react-use';
import {HoursInput} from '@components/salary-calculator/hours-input';
import {HoursOutput} from '@components/salary-calculator/hours-output';
import {Select} from '@components/form';
import {API} from 'lib/api';
import {FC, useRef} from 'react';
import {RadioGroup} from '@headlessui/react';

interface TabProps {
    title: string;
    name: string;
    icon: IconDefinition;
    value: string;
}

const Tab = (props: TabProps) => {
    const active = true
    const tabClasses = (active: boolean) => classNames('select-none cursor-pointer text-xl md:text-[1.75rem] lg:text-[2rem] font-black sm:grow sm:basis-1',
        'rounded-t-[20px] sm:rounded-t-[30px] px-6 sm:px-6 py-4',
        'flex justify-center items-center',
        {
            'bg-white text-rodin-900 grow shrink-0 !px-3': active,
            'bg-gradient-slight from-gold-500 to-gold-400 text-white shrink': !active
        })
    return (
        <label className={tabClasses(active)}>
            <Field name={props.name} type="radio" value={props.value} className="sr-only"/>
            <span className={classNames({'hidden sm:inline': !active, 'inline': active})}>{props.title}</span>
            <span className={classNames({'hidden': active, 'inline sm:hidden': !active})}>
                    <FontAwesomeIcon icon={props.icon} size="lg"/>
                </span>
        </label>
    )
}

export const Tabs = () => {
    return (
        <div
            className="flex space-x-2 sm:grid sm:grid-cols-3 sm:gap-[8px] sm:space-x-0 md:grid md:grid-cols-3 md:gap-[24px] md:space-x-0">
            <div
                className={classNames('select-none cursor-pointer text-xl md:text-[1.75rem] lg:text-[2rem] font-black sm:grow sm:basis-1',
                    'rounded-t-[20px] sm:rounded-t-[30px] px-6 sm:px-6 py-4',
                    'flex justify-center items-center',
                    'bg-white text-rodin-900 grow shrink-0 !px-3'
                )}>
                Røktarfólk
            </div>
        </div>
    )
}

const RadioIcon: FC<{ checked: boolean }> = ({checked}) => {
    return (
        <div className="w-4 h-4 rounded-full border border-gray-300 flex items-center justify-center cursor-pointer">
            <div className={classNames('w-2.5 h-2.5 bg-gold-400 rounded-full', {
                    'opacity-0': !checked,
                    'opacity-100': checked
                }
            )}></div>
        </div>
    )
}
export const WeeksInput = () => {
    const name = 'weeks'
    const {getFieldHelpers, getFieldMeta} = useFormikContext()
    const {setValue} = getFieldHelpers('weeks')
    const {value} = getFieldMeta('weeks')

    return (
        <RadioGroup value={value} onChange={setValue} className="grid grid-cols-1 gap-y-1">
            <RadioGroup.Option value={2}>
                {({checked}) => (
                    <div className="flex items-center">
                        <RadioIcon checked={checked}/>
                        <span className="ml-6">annahvørt vikuskifti</span>
                    </div>
                )}
            </RadioGroup.Option>
            <RadioGroup.Option value={1.3}>
                {({checked}) => (
                    <div className="flex items-center">
                        <RadioIcon checked={checked}/>
                        <span className="ml-6">triðjahvørt vikuskifti**</span>
                    </div>
                )}
            </RadioGroup.Option>
            <RadioGroup.Option value={1}>
                {({checked}) => (
                    <div className="flex items-center">
                        <RadioIcon checked={checked}/>
                        <span className="ml-6">fjóðrahvørt vikuskifti</span>
                    </div>
                )}
            </RadioGroup.Option>
        </RadioGroup>
    )
}
export const SalaryCalculator = () => {
    const touched = useRef(false)

    const {saturday, sunday} = useCalculatorInput()
    const dayShiftTotal = sunday + saturday
    const isDesktop = useMedia('(min-width: 768px)')
    const educationOptions = API.getEducationOptions()
    return (
        <div>
            <Form>
                <Tabs/>
                <div className="bg-white rounded-b-[20px] md:rounded-b-[30px] md:rounded-tr-[30px]">
                    <div className="py-5">
                        <div className="flex w-full grid grid-cols-1 md:grid-cols-3 sm:gap-[24px]">
                            <div className="pl-9 pr-8">
                                <Select name="education" placeholder="Uttan útbúgving"
                                        options={educationOptions}/>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-3 gap-6 py-4">
                            <div className="ml-9 mr-8">
                                <h3 className="text-rodin-900 font-black mb-3">Vaktir</h3>
                                <div className="grid grid-rows-3 gap-2">
                                    <HoursInput/>
                                </div>
                            </div>
                            <div className="mx-8 flex flex-col">
                                <h3 className="text-rodin-900 font-black mb-3">Hvussu ofta ynskir tú at arbeiða?</h3>
                                <WeeksInput/>
                            </div>
                            <div className="mx-8 mr-9 flex flex-col">
                                <h3 className="text-rodin-900 font-black mb-3">Tímatal um mánaðin</h3>
                                <HoursOutput/>
                            </div>
                        </div>
                        <SalaryCalculatorOutput dark/>
                        <div className="px-6 grid md:grid-cols-3 my-8 sm:gap-[24px]">
                            <Button className="mt-auto w-full md:col-start-2">Søk starvið</Button>
                        </div>
                    </div>
                </div>
                {/*{isDesktop && <SalaryCalculatorOutput/>}*/}
            </Form>
        </div>
    )
}
